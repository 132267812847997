import { Controller } from "@hotwired/stimulus"

declare global {
    interface Window {
        mapboxgl: any
    }
}

export default class extends Controller {
    interval: number = 0

    connect() {
        if (window.mapboxgl) {
            this.initialize()
            return;
        }

        this.interval = window.setInterval(() => {
            if (window.mapboxgl) {
                window.clearInterval(this.interval)
                this.interval = 0
                this.initialize()
            }
        }, 100)
    }

    disconnect() {
        if (this.interval) {
            window.clearInterval(this.interval)
        }
    }

    initialize() {
        const data = (this.element as HTMLElement).dataset as {
            latLong: string,
            token: string
        }

        const center = data.latLong.split(',').map(value => parseFloat(value))

        window.mapboxgl.accessToken = data.token

        const map = new window.mapboxgl.Map({
            container: this.element.id,
            style: 'mapbox://styles/shofsky/ckmtwcd0c4fc718p1f3xre9rc',
            center: center, // starting position [lng, lat]
            zoom: 15 // starting zoom
        });

        const marker = new window.mapboxgl.Marker()
            .setLngLat(center)
            .addTo(map)

        map.addControl(new window.mapboxgl.FullscreenControl())
        map.addControl(new window.mapboxgl.NavigationControl())
    }
}
