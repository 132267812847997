import { Controller } from "@hotwired/stimulus"

export default class AccordionController extends Controller {

    static targets = ["accordionItem"];

    static values = {
        openItem: { type: String, default: '' }
    }

    declare isOpenItemValue: string

    toggle(event: Event) {
        const target = <HTMLElement>event.currentTarget

        // Close all
        this.targets.findAll('accordionItem').forEach((item) => {
            const panel = document.getElementById(item.getAttribute('aria-controls'))
            const svg = item.querySelector('svg')

            if (panel) {
                panel.style.maxHeight = null
                if (svg) {
                    svg.classList.remove('rotate-180')
                }
                item.setAttribute('aria-expanded', false)
            }

        });

        // Open panel is not the current panel
        if (this.isOpenItemValue != target.id) {
            const svg = target.querySelector('svg')
            const panel = document.getElementById(target.getAttribute('aria-controls'))
            if (panel) {
                panel.style.maxHeight = panel.scrollHeight + 'px'
                if (svg) {
                    svg.classList.add('rotate-180')
                }
                target.setAttribute('aria-expanded', true)
                this.isOpenItemValue = target.id
            }
        } else {
            // Clear if we clicked the current item
            this.isOpenItemValue = null
        }
    }

}
