import { Controller } from "@hotwired/stimulus"

export default class ResizeController extends Controller<HTMLImageElement> {
    resizeObserver: ResizeObserver
    intersectionObserver: IntersectionObserver

    connect() {
        this.element[this.identifier] = this

        this.intersectionObserver = new IntersectionObserver(
            (elements) => {
                for (const element of elements) {
                    if (
                        element.target instanceof HTMLImageElement &&
                        element.isIntersecting
                    ) {
                        const image = element.target
                        const imgWidth = image.getBoundingClientRect().width
                        if (imgWidth == 0 || image.dataset["processed"])
                            continue

                        image.dataset["processed"] = "true"

                        image.parentNode
                            .querySelectorAll("source")
                            .forEach((source) => {
                                source.sizes =
                                    Math.ceil(
                                        (imgWidth / window.innerWidth) * 100
                                    ) + "vw"
                            })

                        if (!image.classList.contains("unblur")) {
                            image.onload = () => {
                                image.classList.add("unblur")
                                image.classList.remove("blur")
                            }
                        }
                    }
                }
            },
            {
                rootMargin: "1000px 100%",
                threshold: 0,
            }
        )

        /*
        this.resizeObserver = new ResizeObserver((elements) => {
            for (const element of elements) {
                if (element.target instanceof HTMLImageElement) {
                    const image = element.target
                    const imgWidth = image.getBoundingClientRect().width
                    if (imgWidth == 0) continue

                    image.parentNode
                        .querySelectorAll("source")
                        .forEach((source) => {
                            source.sizes =
                                Math.ceil(
                                    (imgWidth / window.innerWidth) * 100
                                ) + "vw"
                        })

                    if (!image.classList.contains("unblur")) {
                        image.onload = () => {
                            image.classList.add("unblur")
                            image.classList.remove("blur")
                        }
                    }
                }
            }
        })
 */
    }

    disconnect() {
        super.disconnect()
        this.intersectionObserver.disconnect()
    }

    observe(element: HTMLElement) {
        this.intersectionObserver.observe(element)
    }
}
