import { ModalController, TargetData } from "./modal_controller"

export default class PopupController extends ModalController {

    static targets = ["message", "careerMessage"]

    declare readonly messageTarget: HTMLElement
    declare readonly careerMessageTarget: HTMLElement

    positionModal(data) {
        const modalTop = window.matchMedia("(max-width: 1023px)").matches
            ? 0
            : 80

        //The default close method will scroll to currentScrollY, so we need to set it here
        this.currentScrollY = window.scrollY

        this.popupTarget.style.marginTop = modalTop + "px"
        this.modalTarget.classList.add("active")

        this.setFocus(this.content)
    }

    /**
     * Show the career message
     */
    setCareersContent() {
        this.messageTarget.classList.add("hidden")
        this.careerMessageTarget.classList.remove("hidden")
    }
    setDefaultContent() {
        this.careerMessageTarget.classList.add("hidden")
        this.messageTarget.classList.remove("hidden")
    }

    /**
     * Set the content mode
     */
    open(event: Event) {
      if (window.location.pathname.includes("careers")) {
        this.setCareersContent()
      } else {
        this.setDefaultContent()
      }
      super.open(event);
    }

  /**
     * Reset the messaging
     */
    close() {
        this.setDefaultContent()

        super.close()
    }

    get content(): HTMLElement {
        return this.targets.find("content") as HTMLElement
    }
}
