import { Controller } from "@hotwired/stimulus"

export interface TargetData {
    id?: string
    dispatch?: string
    modalPosition?: string
    modalScrollTo?: string
}

export class ModalController extends Controller<HTMLElement> {
    static targets = ["popup", "modal", "content", "close", "background"]

    declare readonly popupTarget: HTMLElement
    declare readonly modalTarget: HTMLElement
    declare readonly contentTarget: HTMLElement
    declare readonly closeTarget: HTMLElement
    declare readonly backgroundTarget: HTMLElement

    headerHeight: number = 0
    currentScrollY: number = 0
    onKeydown: any

    initiatingElement: HTMLElement
    contentElement: HTMLElement

    links: HTMLElement[]

    connect() {
        // Add this controller to body element, so it can be called for other controllers
        this.element[this.identifier] = this

        const navElements = document.getElementsByTagName("nav")
        if (navElements.length) {
            this.headerHeight =
                document.getElementsByTagName("nav")[0].offsetHeight
        }

        const url = new URL(window.location.href)
        const pathSegments = url.pathname.split('/').filter(segment => segment.length > 0)

        // Set focus on modal if it is open
        if (this.modalTarget.classList.contains("active") && pathSegments.length == 2) {
            this.allowBodyScroll(false)
            /* Attach the keydown listener so we can customize keyboard navigation */
            this.onKeydown = this.keydown.bind(this)
            document.addEventListener("keydown", this.onKeydown)

            // Get the data
            let data : TargetData
          this.contentTargets.forEach((content) => {
                if (content.dataset.id == pathSegments[1]) {
                    data = content.dataset as TargetData
                }
            })

            // Set the content
            this.initializeContent(data)
            this.modalTarget.classList.add("active")
        }
    }

    disconnect() {
        if (this.onKeydown) {
            document.removeEventListener("keydown", this.onKeydown)
        }
    }

    open(event: Event) {

        if (!this.isValidOpenEvent(event)) return
        event.preventDefault()

        this.initiatingElement = event.target as HTMLElement

        /* Attach the keydown listener so we can customize keyboard navigation */
        this.onKeydown = this.keydown.bind(this)
        document.addEventListener("keydown", this.onKeydown)

        const data = (event.currentTarget as HTMLElement).dataset as TargetData

        // Set history state
        if (data.id && window.location.pathname.includes("careers")) {
            window.history.pushState(
                {},
                "",
                window.location.origin +
                window.location.pathname +
                "/" +
                data.id
            )
        }

        this.initializeContent(data)
        this.modalTarget.classList.add("active")
        this.allowBodyScroll(false)
        this.setFocus()

        if (data.dispatch) {
            event.currentTarget?.dispatchEvent(
                new Event(data.dispatch, { bubbles: true })
            )
        }
    }

    /*
     * Modal can be opened via keypress, but we need to check that only spaces and returns
     * open the modal
     */
    isValidOpenEvent(event: Event): Boolean {
        if (!(event instanceof KeyboardEvent)) {
            return true
        }

        return [" ", "Enter"].includes((event as KeyboardEvent).key);
    }

    initializeContent(data: TargetData) {

        this.contentTargets.forEach((content) => {
            if (content.dataset.id == data.id) {
                content.classList.remove("hidden")
                this.contentElement = content
            } else {
                content.classList.add("hidden")
            }
        })

        // Add accessible name to modal
        const heading = this.contentTarget.querySelector("[data-heading]")
        if (heading) {
            this.modalTarget.setAttribute("aria-labelledby", heading.id)
        }

    }

    /**
     * Set the focus on the first link/button to enable keyboard navigation
     */
    setFocus() {
        /*
         * Set the focus on the first link/button to enable keyboard navigation
         */
        this.links = (
            Array.from(this.contentElement.querySelectorAll("a, button, [tabindex]")) as HTMLElement[]
        ).filter((element: HTMLElement & { ariaHidden: string }) => {
            return element.ariaHidden != "true"
        })

        /**
         * Include the close button in the keyboard navigation
         */
        this.links.push(this.closeTarget)
        this.links[0].focus()
    }


    close() {
        this.allowBodyScroll(true)

        if (this.onKeydown) {
            document.removeEventListener("keydown", this.onKeydown)
        }

        if (window.location.pathname.startsWith("/careers/")) {
            window.history.replaceState({}, "", "/careers")
        }

        this.contentTargets.forEach((content) => {
            content.classList.add("hidden")
        })

        this.modalTarget.classList.remove("active")

        document.body.classList.remove("with-mobile-nav")

        if (this.initiatingElement) {
            this.initiatingElement.focus()
        }
    }

    keydown(event: KeyboardEvent) {
        switch (event.key) {
            case "Tab":
                if (!this.links || this.links.length == 0) {
                    return
                }

                const target = event.target as HTMLElement
                let nextLink: HTMLElement = this.links[0]

                this.links.forEach((link, index) => {

                    if (link != target) {
                        link.tabIndex = -1
                        return
                    }

                    if (event.shiftKey) {
                        nextLink =
                            index == 0
                                ? this.links[this.links.length - 1]
                                : this.links[index - 1]
                    } else {
                        nextLink =
                            index < this.links.length - 1
                                ? this.links[index + 1]
                                : this.links[0]
                    }
                })

                nextLink.tabIndex = 0
                nextLink.focus()

                event.preventDefault()
                break

            case "Escape":
                event.preventDefault()
                this.close()
        }
    }

  allowBodyScroll(allow: boolean) {
    if (allow) {
      document.body.style.overflow = "auto"
      document.body.style.paddingRight = "0"
    } else {
      document.body.style.overflow = "hidden"
      document.body.style.paddingRight = this.getScrollbarWidth() + "px"
    }
  }
  getScrollbarWidth() {
    const outer = document.createElement("div")
    outer.style.visibility = "hidden"
    outer.style.width = "100px"
    outer.style.msOverflowStyle = "scrollbar" // needed for WinJS apps

    document.body.appendChild(outer)

    const widthNoScroll = outer.offsetWidth
    // force scrollbars
    outer.style.overflow = "scroll"

    // add inner div
    const inner = document.createElement("div")
    inner.style.width = "100%"
    outer.appendChild(inner)

    const widthWithScroll = inner.offsetWidth

    // remove divs
    outer.parentNode?.removeChild(outer)

    return widthNoScroll - widthWithScroll
  }

    /*
    get popupTarget(): HTMLElement {
        return this.targets.find("popup") as HTMLElement
    }

    get modalTarget(): HTMLElement {
        return this.targets.find("modal") as HTMLElement
    }

    get backgroundTarget(): HTMLElement {
        return this.targets.find("background") as HTMLElement
    }

 */

    get contentTargets(): HTMLElement[] {
        return this.targets.findAll("content") as HTMLElement[]
    }
}

export default ModalController
