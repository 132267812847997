import { Controller } from "@hotwired/stimulus"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class extends Controller {

    html = document.documentElement;

    frames = {
        sm: {
            folder: (this.element as HTMLElement).dataset.folder,
            count: Number((this.element as HTMLElement).dataset.frameCount),
            ratio: .4618,
            width: window.innerHeight * .4618,
            height: window.innerHeight,
            images: []
        },
        lg: {
            folder: (this.element as HTMLElement).dataset.lgFolder,
            count: Number((this.element as HTMLElement).dataset.lgFrameCount),
            ratio: 1.778,
            width: window.innerHeight * 1.778,
            height: window.innerHeight,
            images: []
        }
    }

    activeFrame = (window.innerWidth > 768) ? 'lg' : 'sm';

    calculateScreen() {

        // Set the activeFrame
        if (window.innerWidth > 768) {

            if (this.activeFrame !== 'lg') {
                this.activeFrame = 'lg';
            }

            if (window.innerHeight != this.frames.lg.height) {
                this.frames.lg.width = window.innerHeight * this.frames.lg.ratio;
                this.frames.lg.height = window.innerHeight;
            } else {
                this.frames.lg.width = window.innerWidth;
                this.frames.lg.height = window.innerWidth/this.frames.lg.ratio;
            }

        } else if (window.innerWidth <= 768 ) {

            if (this.activeFrame !== 'sm') {
                this.activeFrame = 'sm';
            }

            if (window.innerHeight != this.frames.sm.height) {
                this.frames.sm.width = window.innerHeight * this.frames.sm.ratio;
                this.frames.sm.height = window.innerHeight;
            } else {
                this.frames.sm.width = window.innerWidth;
                this.frames.sm.height = window.innerWidth/this.frames.sm.ratio;
            }
        }

        // Get images if they haven't been loaded
        if (this.frames[this.activeFrame].images.length == 0) {
            for (let i = 0; i < this.frames[this.activeFrame].count; i++) {
                let img = new Image();
                img.src = this.getFrame(i);
                this.frames[this.activeFrame].images.push(img);
            }
        }
    }

    getFrame(index: number) {
        return `/${this.frames[this.activeFrame].folder}/${index.toString().padStart(5, '0')}.jpg`
    }

    initializeCanvas(section: any, canvas: HTMLCanvasElement) {

        const text = section.querySelectorAll(".sequence-text");
        const context = canvas.getContext("2d");

        canvas.width = this.frames[this.activeFrame].width;
        canvas.height = this.frames[this.activeFrame].height;

        //const images = []
        const frames = {
            frame: 0
        };

        const render = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(this.frames[this.activeFrame].images[frames.frame], 0, 0, canvas.width, canvas.height);
        }

        const resize = () => {

            // Size canvas
            canvas.width = this.frames[this.activeFrame].width;
            canvas.height = this.frames[this.activeFrame].height;

            // Rerender images
            render();
        }

        // Run the default sequence
        for (let i = 0; i < this.frames[this.activeFrame].count; i++) {
            let img = new Image();
            img.src = this.getFrame(i);
            this.frames[this.activeFrame].images.push(img);
        }

        // Create timeline
        const timeline = gsap.timeline({
            onUpdate: render,
            scrollTrigger: {
                trigger: section,
                pin: this.element,
                scrub: 0.5,
                end: () => `+=${canvas.height}`,
                markers: false
            }
        })

        // Add the text tweens
        for (let i = 0; i < text.length; i++) {
            const id = text[i].id;
            const position = Number(text[i].dataset.position);
            const duration = Number(text[i].dataset.duration);

            timeline.to("#" + id, {
                opacity: 1,
                duration: 0.5
            },position).to("#" + id, {
                opacity: 0,
                duration: 0.5
            }, position + duration)
        }

        // Add sequence tween
        timeline.to(frames, {
            frame: this.frames[this.activeFrame].count - 1,
            snap: "frame",
            ease: "none",
            duration: 56
        }, 0);

        this.frames[this.activeFrame].images[0].onload = render;

        // Listen for resize
        window.addEventListener('resize', () => {
            this.calculateScreen();
            resize();
        });
    }

    initialize() {

        gsap.registerPlugin(ScrollTrigger)

        const sections = gsap.utils.toArray(this.element.querySelectorAll("section"))

        sections.forEach(section => {
            const canvas = (section as HTMLElement).querySelector("canvas");
            canvas ? this.initializeCanvas(section, canvas) : null;
        });
    }
}
