import { Controller } from "@hotwired/stimulus"
import ResizeController from "./resize_controller"

export default class LazyloadController extends Controller<HTMLImageElement> {
    static values = {
        sizes: Array,
        version: String,
    }

    declare readonly versionValue: string
    declare readonly sizesValue: number[][]

    connect() {
        if (!document.body["resize"]) {
            console.log("missing resize controller")
        }

        const source = (
            this.element.parentElement as HTMLPictureElement
        ).querySelector("source")

        const path = "/_/" + new URL(this.element.src).pathname.substring(1)

        source.srcset =
            (source.srcset ? `${source.srcset}, ` : "") +
            this.sizesValue
                .map(
                    (sizes) =>
                        `${path}/w_${sizes[0]}:h_${sizes[1]}:q_90:fm_webp/${this.versionValue}.webp ${sizes[0]}w`
                )
                .join(", ")

        const resizeController = document.body["resize"] as ResizeController
        resizeController.observe(this.element)
    }
}
