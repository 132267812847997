import { Controller } from "@hotwired/stimulus"
import PopupController from "./popup_controller"

export default class extends Controller {
    blurHandler: any
    targetLink: HTMLAnchorElement

    disconnect() {
        this.cleanup()
    }

    /*
     * If the modal was closed directly then the blur event listener won't be cleaned up
     */
    cleanup() {
        if (this.targetLink && this.blurHandler) {
            this.targetLink.removeEventListener("blur", this.blurHandler)
            this.targetLink = null
            this.blurHandler = null
        }
    }

    confirm(event: Event) {
        this.cleanup()

        this.targetLink = this.getTarget(event)
        if (!this.targetLink) {
            return
        }

        this.anchor.href = this.targetLink.href
        event.preventDefault()
        this.popup.open(event)
    }

    getTarget(event: Event): HTMLAnchorElement|null {
        const target = (event.target as HTMLElement).closest(
            "a"
        ) as HTMLAnchorElement

        // Not a link click
        if (!target?.href) {
            return null
        }

        // Handle in-page anchor links with focus per WCAG
        if (target.href.includes("#")) {
            const [url, anchor] = target.href.split("#")
            if (url == window.location.href) {
                const target = document.getElementById(anchor)
                if (target) {
                    target.focus()
                    return null
                }
            }
        }
        const vimeoUrlPattern = /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/

        // Local navigation link or vimeo embed
        if (
            target.href.startsWith("/") ||
            target.href.includes(window.location.hostname) ||
            target.href.includes("ir.entradatx.com") ||
            target.href.includes("assets.entradatx.com") ||
            target.href.match(vimeoUrlPattern) ||
            target.href.includes("player.vimeo.com")
        ) {
            return null
        }

        // Click on the popup OK button
        if (target.dataset.linkTarget || target.dataset.externalLink) {
            return null
        }

        return target
    }

    blur() {
        this.anchor.removeEventListener("blur", this.blurHandler)
    }

    confirmed() {
        this.cleanup()
        this.popup.close()
    }

    denied() {
        this.cleanup()
        this.popup.close()
    }

    get popup(): PopupController {
        return document.body["popup"] as PopupController
    }

    get anchor(): HTMLAnchorElement {
        return this.targets.find("anchor") as HTMLAnchorElement
    }
}
