import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller<HTMLElement> {
    onBeforeVisit: any
    onScroll: any
    onDebouncedScroll: any

    currentScrollY: number
    navHeight: number

    constructor(context) {
        super(context)
        this.navHeight = window.matchMedia("(max-width: 1023)").matches
            ? 80
            : 110

        this.onBeforeVisit = this.beforeVisit.bind(this)
        this.onScroll = this.scroll.bind(this)
        this.onDebouncedScroll = this.debouncedScroll.bind(this)
    }

    static targets = ["menu", "menuItem", "mobileButton", "mobileMenu"];

    static values = {
        isMobileOpen: { type: Boolean, default: false },
        isSubMenuOpen: { type: Boolean, default: false },
        menuIndex: { type: Number, default: -1 },
    };

    declare isMobileOpenValue: boolean
    declare isSubMenuOpenValue: boolean
    declare menuIndexValue: number

    /**
     * The current menu target
     */
    currentTarget: HTMLAnchorElement|null = null;


    /** --------------------
     * Manage state changes
     * ---------------------*/
    /**
     * Handle the mobile menu state change
     */
    isMobileOpenValueChanged() {
        if (!this.isMobileOpenValue) {
            this.closeMobileMenu()
        } else {
            this.openMobileMenu()
        }
    }

    /**
     * Handle the menu index state change
     */
    menuIndexValueChanged(value: number, oldValue: number) {
        // If oldValue is >= 0 then close the old menu
        if (oldValue && oldValue >= 0) {
            const oldButton = this.menuItemTargets[oldValue].querySelector('[aria-expanded]') as HTMLElement;
            if (oldButton) {
                oldButton.ariaExpanded = "false";
            }
            const oldPanel = this.menuItemTargets[oldValue].querySelector(".menu-panel") as HTMLElement;
            if (oldPanel) {
                oldPanel.classList.add("hidden");
                oldPanel.ariaHidden = "true";
            }
        }

        if (value >= 0) {
            const button = this.menuItemTargets[value].querySelector('[aria-expanded]') as HTMLElement;
            if (button) {
                button.ariaExpanded = "true";
            }

            const panel = this.menuItemTargets[value].querySelector(".menu-panel") as HTMLElement;
            if (panel) {
                panel.classList.remove("hidden");
                panel.ariaHidden = "false";
            }
            this.isSubMenuOpenValue = true;
        } else {
            this.isSubMenuOpenValue = false;
        }

    }


    /** --------------------
     * High-level methods
     * ---------------------*/
    /**
     * Escape fallback to close any open menus
     * @param event
     */
    escapeAllMenus(event: KeyboardEvent) {

        if (event.key !== "Escape") {
            return;
        }

        this.menuIndexValue = undefined;
        this.isMobileOpenValue = false;
    }

    /**
     * On resize hide the mobile menu if needed
     */
    resize() {
        let visible = false;
        this.menuTargets.forEach((menu) => {
            if ( menu.offsetWidth || menu.offsetHeight || menu.getClientRects().length ) {
                visible = true;
            }
        })
        if (visible) {
            this.isMobileOpenValue = false;
        }
    }


    /** --------------------
     * Scroll methods
     * ---------------------*/

    /**
     * Manages the navbar during scroll
     */
    scroll() {
        const classList = this.element.classList
        const hamburgerClassList = this.element.querySelector('.hamburger').classList

        if (window.scrollY <= 5) {
            this.currentScrollY = 0
            classList.remove("hide")
            classList.remove("show")
            classList.remove("text-black")
            classList.add("text-white")
            
            hamburgerClassList.remove("hamburger-contrast")

            return
        }

        if (window.scrollY > this.currentScrollY) {
            if (this.currentScrollY > this.navHeight) {
                classList.add("hide")
                classList.remove("show")
                classList.remove("text-white")
                classList.add("text-black")

                hamburgerClassList.remove("hamburger-contrast")

            }
        } else {
            classList.add("show")
            classList.remove("hide")
            classList.remove("text-white")
            classList.add("text-black")

            hamburgerClassList.add("hamburger-contrast")

        }

        this.currentScrollY = window.scrollY
    }

    /**
     * Debounce the scroll event
     */
    debouncedScroll() {
        const delta = window.scrollY - this.currentScrollY
        if (delta * delta < 5) {
            return
        }

        this.onScroll()
    }


    /** --------------------
     * Stimulus targets
     * --------------------*/
    /**
     * Get the menu targets
     */
    get menuTargets(): HTMLElement[] {
        return this.targets.findAll("menu") as HTMLElement[]
    }

    /**
     * Get the menu item targets
     */
    get menuItemTargets(): HTMLElement[] {
        return this.targets.findAll("menuItem") as HTMLElement[]
    }

    /**
     * Get the mobile menu target
     */
    get mobileMenu(): HTMLElement {
        return this.targets.find("mobileMenu") as HTMLElement
    }

    /**
     * Get the mobile button target
     */
    get mobileButtonTarget(): HTMLElement {
        return this.targets.find("mobileButton") as HTMLElement
    }

    /**
     * Get the menu item
     */
    get menuItem(): HTMLElement {
        return this.currentTarget.closest('[data-nav-target="menuItem"]') as HTMLElement
    }

    /**
     * Get the menu panel
     */
    get menuPanel(): HTMLElement {
        return this.currentTarget.closest('.menu-panel') as HTMLElement
    }


    /** --------------------
     * Stimulus lifecycle methods
     * --------------------*/
    /**
     * Hide the menus before visiting a new page
     * @param event
     */
    beforeVisit(event: CustomEvent) {
        if (this.isMobileOpenValue) {
            this.isMobileOpenValue = false;
        }

        if (this.isSubMenuOpenValue) {
            this.menuIndexValue = undefined;
        }
    }

    connect() {
        document.addEventListener("turbo:before-visit", this.onBeforeVisit)
        window.addEventListener("scroll", this.onDebouncedScroll)
        this.currentScrollY = window.scrollY

        // Add event listener for focusout on mobile menu
        this.mobileMenu.addEventListener("focusout", this.focusOutMobile.bind(this))

        // Add event listener to close hover menus
        document.addEventListener("keyup", this.escapeAllMenus.bind(this))
    }

    disconnect() {
        document.removeEventListener("turbo:before-visit", this.onBeforeVisit)
        window.removeEventListener("scroll", this.onDebouncedScroll)

        // Remove event listener for focusout on mobile menu
        this.mobileMenu.removeEventListener("focusout", this.focusOutMobile)

        // Remove event listener to close hover menus
        document.removeEventListener("keyup", this.escapeAllMenus)
    }

    /** --------------------
     * Mobile menu methods
     * ---------------------*/

    /**
     * Close the mobile menu
     */
    closeMobileMenu() {
        this.mobileButtonTarget.classList.remove("active")
        this.mobileButtonTarget.querySelector("span.sr-only").textContent = "Open Main Menu"

        const menu = this.targets.find("mobileMenu") as HTMLElement
        menu.classList.add("hidden")

        document.body.classList.remove("with-mobile-nav")
    }

    /**
     * Open the mobile menu
     */
    openMobileMenu() {
        this.mobileButtonTarget.classList.add("active")
        this.mobileButtonTarget.querySelector("span.sr-only").textContent = "Close Main Menu"

        const menu = this.targets.find("mobileMenu") as HTMLElement
        menu.classList.remove("hidden")

        document.body.classList.add("with-mobile-nav")
    }

    /**
     * Focus out event to close the mobile menu when focus leaves
     * @param event
     */
    focusOutMobile(event: FocusEvent) {
        const relatedTarget = event.relatedTarget as HTMLElement;
        if (relatedTarget && this.mobileMenu.contains(relatedTarget)) {
            return;
        }

        this.isMobileOpenValue = false;
    }

    /**
     * Show or hide the mobile menu
     */
    toggleMobileOpenValue(event: Event) {
        this.isMobileOpenValue = !this.isMobileOpenValue;
    }

    /**
     * Handle keydown events for mobile menu
     * @param event
     */
    mobileNav(event: KeyboardEvent) {

        const target = event.target as HTMLElement
        const menuItem = target.closest('.menu-item') as HTMLElement

        /**
         * Escape key inside the menu moves focus to the button
         */
        if (event.key === "Escape") {
            this.mobileButtonTarget.focus()
            return;
        }

        /**
         * Enter key opens the submenu
         */
        if (event.key === "Enter") {
            // If not a submenu then return and proceed
            if (!target.hasAttribute('aria-expanded')) {
                return
            }

            event.preventDefault()

            // Open the submenu
            target.ariaExpanded = "true"
            const menu = menuItem.querySelector('.submenu') as HTMLElement
            menu.style.maxHeight = 'none'

            // Move focus into the submenu
            menu.querySelector('a').focus()
        }

        /**
         * Move down through the menu
         */
        if (event.key == "ArrowDown" || (event.key == "Tab" && !event.shiftKey)) {

            if (target.ariaExpanded == "true") {

                // Menu is open so move into the submenu
                ;(menuItem.querySelector('.submenu a') as HTMLElement).focus()

                event.preventDefault()

            } else if (target.closest('.submenu')) {

                // If main item then move to the next item
                const submenu = target.closest('.submenu') as HTMLElement
                const links = submenu.querySelectorAll('a')
                let nextLink: HTMLElement

                links.forEach((link, index) => {
                    if (link == target) {
                        nextLink = index < links.length - 1 ? links[index + 1] : null
                    }
                })

                if (nextLink) {
                    // Focus on next link
                    nextLink.focus()
                } else {
                    // If no next item then close the submenu
                    menuItem.firstElementChild.ariaExpanded = "false"
                    submenu.style.maxHeight = '0'

                    if (menuItem.nextElementSibling) {
                        // If there is a next item then move to it
                        ;(menuItem.nextElementSibling.firstElementChild as HTMLElement).focus()
                    }
                }

                event.preventDefault()

            } else if (menuItem.nextElementSibling) {

                // If next go to it
                const next = menuItem.nextElementSibling as HTMLElement
                if (next) {
                    (next.firstElementChild as HTMLElement).focus()
                }

                event.preventDefault()
            } else {
                // If no next item then go to the first
                const first = this.mobileMenu.querySelector('.menu-item').firstElementChild as HTMLElement
                first.focus();

                event.preventDefault();
            }
        }

        /**
         * Move up through the menu
         */
        if (event.key === "ArrowUp" || (event.shiftKey && event.key === "Tab")) {

            if (target.ariaExpanded == "true") {

                // Menu is open so close and move up
                target.ariaExpanded = "false"
                const menu = menuItem.querySelector('.submenu') as HTMLElement
                menu.style.maxHeight = '0'

                if (menuItem.previousElementSibling) {
                    // If there is a previous item then move to it
                    ;(menuItem.previousElementSibling.firstElementChild as HTMLElement).focus()
                }

                event.preventDefault()

            } else if (target.closest('.submenu')) {

                // In the submenu
                const submenu = target.closest('.submenu') as HTMLElement
                const links = submenu.querySelectorAll('a')
                let previousLink: HTMLElement

                links.forEach((link, index) => {
                    if (link == target && index > 0) {
                        previousLink = links[index - 1] as HTMLElement
                    }
                })

                if (previousLink) {
                    previousLink.focus()
                } else {
                    // If no next item then close the submenu
                    (menuItem.firstElementChild as HTMLElement).focus()
                    menuItem.firstElementChild.ariaExpanded = "false"
                    submenu.style.maxHeight = '0'
                }

                event.preventDefault()

            } else if (menuItem.previousElementSibling) {

                const previous = menuItem.previousElementSibling as HTMLElement
                if (previous) {
                    (previous.firstElementChild as HTMLElement).focus()
                }

                event.preventDefault()

            } else {
                // If no next item then go to the first
                const links = this.mobileMenu.querySelectorAll('.menu-item')
                const last = links[links.length - 1].firstElementChild as HTMLElement
                last.focus();

                event.preventDefault();
            }
        }

    }

    /**
     * Toggle the mobile submenu
     * @param event
     */
    toggleMobileSubMenu(event: Event) {
        const target = event.currentTarget as HTMLElement
        const menuItem = target.closest('.menu-item') as HTMLElement

        const submenu = menuItem.querySelector('.submenu') as HTMLElement

        if (target.ariaExpanded == "true") {
            target.ariaExpanded = "false"
            submenu.style.maxHeight = '0'
        } else {
            target.ariaExpanded = "true"
            submenu.style.maxHeight = 'none'
        }
    }


    /* --------------------
     * Desktop menu methods
     * ------------------- */
    /**
     * Open submenu
     * @param event
     */
    openMenuPanel(event: MouseEvent) {
        this.menuItemTargets.forEach((menu, index) => {
            if (menu == event.currentTarget) {
                this.menuIndexValue = index
            }
        })
    }

    /**
     * Close submenu
     * @param event
     */
    closeMenuPanel(event: MouseEvent) {
        const related = event.relatedTarget as HTMLElement;
        if (related && !related.classList.contains('menu-panel')) {
            this.menuIndexValue = undefined;
        }
    }


    /**
     * Focus on the menu
     * @param menu
     * @param first
     */
    focusSubmenu(menu = this.menuItem, first = true) {
        const panel = menu.querySelector(".menu-panel") as HTMLElement;
        const focusable = panel.querySelectorAll('a, button')

        // Use the timeout so the submenu is open before we try to focus on it
        if (first) {
            let target = focusable[0] as HTMLElement;
            setTimeout(() => {
                target.focus();
            }, 0);
        } else {
            let target = focusable[focusable.length - 1] as HTMLElement;
            setTimeout(() => {
                target.focus();
            }, 0);
        }

    }

    /**
     * Move to next menu item
     */
    next(event: KeyboardEvent) {

        // Top-level handling
        if (this.currentTarget.hasAttribute("aria-expanded")) {

            event.preventDefault()

            // Submenu is closed, so we move the index
            if (!this.isSubMenuOpenValue) {
                this.menuItemTargets.forEach((menu, index) => {
                    if (menu == this.menuItem) {
                        this.menuIndexValue = index
                    }
                })
            }

            // Focus on the submenu
            this.focusSubmenu()

            return
        }

        // Nested menu movement
        const panel = this.menuPanel as HTMLElement
        if (panel) {

            event.preventDefault()

            const links = panel.querySelectorAll('a')
            let nextLink: HTMLElement


            // Check if there is a next link to focus
            links.forEach((link, index) => {
                if (link == this.currentTarget) {
                    nextLink = index < links.length - 1 ? links[index + 1] : null
                }
            })

            if (nextLink) {
                // Focus on the next link if there is one
                nextLink.focus()
            } else {
                // Open menu if it exists
                if (this.menuItem.nextElementSibling) {
                    this.menuItemTargets.forEach((menu, index) => {
                        if (menu == this.menuItem.nextElementSibling) {
                            this.menuIndexValue = index
                        }
                    })
                    ;(this.menuItem.nextElementSibling.firstElementChild as HTMLElement).focus()
                } else {
                    this.menuIndexValue = undefined
                }
            }
        }
    }

    /**
     * Move to previous menu item
     * @param event
     */
    previous(event: KeyboardEvent) {

        // If the target is top-level, then...
        if (this.currentTarget.hasAttribute('aria-expanded')) {

            if (this.isSubMenuOpenValue && this.menuItem.previousElementSibling) {
                event.preventDefault()

                // If the submenu is open and there is a previous item, then close and move to the previous menu item
                const previousMenu = this.menuItem.previousElementSibling as HTMLElement;
                let previousMenuIndex: number;
                this.menuItemTargets.forEach((menu, index) => {
                    if (menu == previousMenu) {
                        previousMenuIndex = index;
                    }
                })

                if (previousMenuIndex) {
                    this.menuIndexValue = previousMenuIndex;
                    // Focus on the last element in the new menu
                    this.focusSubmenu(previousMenu, false)
                } else {
                    this.menuIndexValue = undefined;
                }

            } else if (this.menuItem.previousElementSibling) {
                event.preventDefault()

                // If the submenu is close and there is a previous item, move to the previous item
                const previous = this.menuItem.previousElementSibling as HTMLElement;
                (previous.firstElementChild as HTMLElement).focus();
            } else {
                // Default is to close the menu
                this.menuIndexValue = undefined;
            }

            return
        }

        // Nested menu movement
        const panel = this.menuPanel as HTMLElement
        if (panel) {

            event.preventDefault()

            const links = panel.querySelectorAll('a')
            let nextLink: HTMLElement

            // Check if there is a previous link to focus
            links.forEach((link, index) => {
                if (link == this.currentTarget) {
                    nextLink = index === 0 ? null : links[index - 1]
                }
            })

            if (nextLink) {
                // Focus on the previous link
                nextLink.focus()
            } else {
                // Move to the menu item
                (this.menuItem.firstElementChild as HTMLElement).focus()
            }
        }
    }

    /**
     * Handle keydown events for mega menu
     * @param event
     */
    keydown(event: KeyboardEvent) {

        if (!(event.target instanceof HTMLElement)) {
            return
        }

        this.currentTarget = event.target as HTMLAnchorElement;


        /**
         * WCAG: Move left through the menu
         * @param event
         */
        if (event.key == "ArrowLeft") {
            if (this.menuPanel && this.menuItem.previousElementSibling) {

                // We are in a submenu so...
                if (this.currentTarget.closest('.menu-panel-group')) {

                    // We are also in a panel group...
                    const currentPanel = this.currentTarget.closest('.menu-panel-group') as HTMLElement
                    const panelGroups = this.menuPanel.querySelectorAll('.menu-panel-group')

                    if (panelGroups.length > 1) {
                        // Find and go to the previous panel group
                        let panel: HTMLElement;
                        panelGroups.forEach((panelGroup, index) => {
                            if (panelGroup == currentPanel) {
                                panel = index === 0 ? null : panelGroups[index - 1] as HTMLElement;
                            }
                        })
                        if (panel) {
                            (panel.querySelector('a, button') as HTMLElement).focus()
                        } else {
                            // No previous panel so go to the previous menu item
                            const previous = this.menuItem.previousElementSibling as HTMLElement;
                            let previousMenuIndex: number;
                            this.menuItemTargets.forEach((menu, index) => {
                                if (menu == previous) {
                                    previousMenuIndex = index;
                                }
                            })
                            this.menuIndexValue = previousMenuIndex;
                        }
                    } else {
                        // Go to the previous menu item
                        const previous = this.menuItem.previousElementSibling as HTMLElement;
                        let previousMenuIndex: number;
                        this.menuItemTargets.forEach((menu, index) => {
                            if (menu == previous) {
                                previousMenuIndex = index;
                            }
                        })
                        this.menuIndexValue = previousMenuIndex;
                    }
                }

                // We are not in a panel group so focus on the menu item
                (this.menuItem.previousElementSibling.firstElementChild as HTMLAnchorElement).focus()

            } else if (this.menuItem.previousElementSibling) {
                // If menu is opened then move to the next sibling and switch menu
                if (this.currentTarget.hasAttribute("aria-expanded") && this.currentTarget.ariaExpanded == "true") {
                    const previous = this.menuItem.previousElementSibling as HTMLElement;
                    let previousMenuIndex: number;
                    this.menuItemTargets.forEach((menu, index) => {
                        if (menu == previous) {
                            previousMenuIndex = index;
                        }
                    })
                    this.menuIndexValue = previousMenuIndex;
                    (previous.firstElementChild as HTMLAnchorElement).focus();
                } else {
                    this.menuIndexValue = undefined;
                    ;(this.menuItem.previousElementSibling.firstElementChild as HTMLElement).focus()
                }

            }

            return
        }

        /**
         * WGAC: Move right through the menu
         * @param event
         */
        if (event.key == "ArrowRight") {
            if (this.menuPanel && this.menuItem.nextElementSibling) {

                // We are in a submenu so...
                if (this.currentTarget.closest('.menu-panel-group')) {

                    // We are also in a panel group...
                    const currentPanel = this.currentTarget.closest('.menu-panel-group') as HTMLElement
                    const panelGroups = this.menuPanel.querySelectorAll('.menu-panel-group')

                    if (panelGroups.length > 1) {
                        // Find and go to the previous panel group
                        let panel: HTMLElement;
                        panelGroups.forEach((panelGroup, index) => {
                            if (panelGroup == currentPanel) {
                                panel = index === panelGroups.length - 1 ? null : panelGroups[index + 1] as HTMLElement;
                            }
                        })
                        if (panel) {
                            (panel.querySelector('a, button') as HTMLElement).focus()
                        } else {
                            // No previous panel so go to the previous menu item
                            const next = this.menuItem.nextElementSibling as HTMLElement;
                            let nextMenuIndex: number;
                            this.menuItemTargets.forEach((menu, index) => {
                                if (menu == next) {
                                    nextMenuIndex = index;
                                }
                            })
                            this.menuIndexValue = nextMenuIndex;
                        }
                    } else {
                        // Go to the previous menu item
                        const next = this.menuItem.nextElementSibling as HTMLElement;
                        let nextMenuIndex: number;
                        this.menuItemTargets.forEach((menu, index) => {
                            if (menu == next) {
                                nextMenuIndex = index;
                            }
                        })
                        this.menuIndexValue = nextMenuIndex
                    }
                }

                // Focus on the next menu item
                (this.menuItem.nextElementSibling.firstElementChild as HTMLAnchorElement).focus()

            } else if (this.menuItem.nextElementSibling) {
                // If menu is opened then move to the next sibling and switch menu
                if (this.currentTarget.hasAttribute("aria-expanded") && this.currentTarget.ariaExpanded == "true") {
                    const previous = this.menuItem.nextElementSibling as HTMLElement;
                    let previousMenuIndex: number;
                    this.menuItemTargets.forEach((menu, index) => {
                        if (menu == previous) {
                            previousMenuIndex = index;
                        }
                    })
                    this.menuIndexValue = previousMenuIndex;
                    (previous.firstElementChild as HTMLAnchorElement).focus();
                } else {
                    this.menuIndexValue = undefined;
                    ;(this.menuItem.nextElementSibling.firstElementChild as HTMLElement).focus()
                }
            }

            return
        }

        /**
         * WCAG: Move down through the menu
         */
        if (event.key == "ArrowDown")
        {
            this.next(event);

            return
        }

        /**
         * WCAG: Move up through the menu
         */
            if (event.key == "ArrowUp") {

            this.previous(event)
            return
        }

        if (event.key == "Tab") {

            if (event.shiftKey && (this.menuItem.previousElementSibling || this.isSubMenuOpenValue)) {
                this.previous(event)
            } else if (this.menuItem.nextElementSibling)            {
                this.next(event)
            }

            return
        }

        /**
         * Enter key goes to the link
         */
        if ([" ", "Enter"].includes(event.key)) {
            event.preventDefault();
            Turbo.visit(this.currentTarget.href)
        }
    }
}
