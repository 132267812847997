import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    change() {
        const urlSearchParams = new URLSearchParams(window.location.search);

        const currentYear = urlSearchParams.has('year') ? urlSearchParams.get('year') : 0
        const year = parseInt((this.element as HTMLSelectElement).value)
        if(year != currentYear) {
            window.location.assign(`${window.location.origin}${window.location.pathname}${year > 0 ? '?year=' + year : ''}`)
        }
    }
}
