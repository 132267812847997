import { Controller } from "@hotwired/stimulus"

export default class ShofskyController<
    ElementType extends Element = Element
> extends Controller<ElementType> {
    eventListeners: {
        target: EventTarget
        type: string
        handler: EventListener
    }[] = []

    addEventListener(
        target: EventTarget,
        type: string,
        handler: EventListener
    ) {
        const boundHandler = handler.bind(this)
        target.addEventListener(type, boundHandler)

        this.eventListeners.push({
            type,
            handler,
            target,
        })
    }

    disconnect() {
        for (const listener of this.eventListeners) {
            listener.target.removeEventListener(listener.type, listener.handler)
        }
    }

    get isTouchEnabled(): boolean {
        return (
            "ontouchstart" in window ||
            navigator.maxTouchPoints > 0 ||
            navigator["msMaxTouchPoints"] > 0
        )
    }

    show(targets: HTMLElement | HTMLElement[]) {
        const showElement = (element: HTMLElement) => {
            element.classList.remove("hidden")
            if (element.classList.contains("opacity-0")) {
                element.classList.remove("opacity-0")
                element.dataset["hiddenOpacity"] = "opacity-0"
            }
        }

        targets instanceof Array
            ? targets.forEach(showElement)
            : showElement(targets)
    }

    hide(targets: HTMLElement | HTMLElement[]) {
        const hideElement = (element: HTMLElement) => {
            element.classList.add("hidden")
            if (element.dataset["hiddenOpacity"]) {
                element.classList.add(element.dataset["hiddenOpacity"])
            }
        }

        targets instanceof Array
            ? targets.forEach(hideElement)
            : hideElement(targets)
    }
}
