// import 'virtual:svg-icons-register'
import '../css/app.css'
import * as Turbo from '@hotwired/turbo'
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
// import './services/rollbar'

// default
import 'lite-youtube-embed'
import 'lite-vimeo-embed/module/lite-vimeo-embed'

import.meta.glob('../img/**/*', { as: 'url', eager: true })
const controllers = import.meta.glob('./controllers/*_controller.ts', { eager: true })


const application = Application.start()
registerControllers(application, controllers)

Turbo.start()

function ensureSpriteExists({ spriteId, spriteUrl }) {
  const existing = document.getElementById(spriteId)

  if (existing) {
    //console.log('sprite exists')
    return
  }
  //console.log('sprite does not exist')
  const script = document.createElement('script')
  script.type = 'module'
  script.src = `${spriteUrl}`
  document.body.insertBefore(script, document.body.childNodes[0])
}

document.addEventListener('turbo:load', () => {

  const anchor = getAnchor(window.location)
  if (anchor) {
    const element = document.getElementById(anchor)
    element && element.scrollIntoView()
  }

  ensureSpriteExists(window.APP.CONFIG ?? {
    spriteId: 'vite-plugin-svg-spritemap',
    spriteUrl: '/svg/sprite.svg',
  })
})

document.addEventListener('turbo:click', () => {
  if (document.activeElement) {
    (document.activeElement as HTMLElement).blur()
  }
})



function getAnchor(url: Location) {
  let anchorMatch
  if (url.hash) {
    return url.hash.slice(1)
  } else if (anchorMatch = url.href.match(/#(.*)$/)) {
    return anchorMatch[1]
  } else {
    return false
  }
}


//Required because we set isolatedModules to true in tsconfig as per vite recommendation
//https://vitejs.dev/guide/features.html#isolatedmodules
